import Vue from 'vue';
import { mapGetters } from 'vuex';
import Config from '@/constants/config';
import Environments from '@/constants/environments';

export const cookieBanner = {
    data() {    
        return {
            userCentricsInjected: false,
        };
    },
    computed: {
        ...mapGetters(['labelSettings', 'language']),
    },
    methods: {
        environment(){
            return Config.ENVIRONMENT;
        },
        loadScriptUsercentrics(){
            if (!this.userCentricsInjected) {  
                const script1 = document.createElement('script');
                
                script1.async = true;
                script1.id = 'usercentrics-cmp';
                script1.setAttribute('data-language', this.language);
                script1.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
                script1.setAttribute('data-settings-id', this.labelSettings.userCentricsId);

                if (this.environment() !== Environments.Production) {
                    script1.setAttribute('data-version', 'preview');
                    script1.setAttribute('data-disable-tracking', '');
                }    
                
                document.head.appendChild(script1);
                this.userCentricsInjected = true;
                
                this.subscribeOnUserCentricsEvent();                
            }            
        },
        subscribeOnUserCentricsEvent() {
            const cookieBanner = this;
            
            window.addEventListener('UC_SDK_EVENT', function (event) {
                // (Only include consent event)
                if(event.detail && event.detail.event === 'consent_status') {
                    cookieBanner.disableOrEnableGoogleAnalytics(event.detail['Google Analytics']);
                }                  
            });
        },
        disableOrEnableGoogleAnalytics(shouldEnable){
            if (this.environment() === Environments.Production && this.labelSettings.label === 'aev') {
                if (shouldEnable) {
                    Vue.$gtag.optIn();
                }
                else {
                    Vue.$gtag.optOut();
                    window['ga-disable-G-EPGQZCY7TD'] = true;
                } 
            }                         
        }
    },
    watch: {
        language(lang) {
            if (lang) {
                this.loadScriptUsercentrics();
            }            
        }
    }
};