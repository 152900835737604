import Config from '@/constants/config';
import { mapGetters } from 'vuex';

export const liveChat = {
    data() {
    return {
        injected: false,
    };
  },
    mounted(){  
        this.loadScript();
        // window.addEventListener('resize', this.onResize);
    },
    computed:{
        ...mapGetters(['labelSettings']),
        liveChatId(){
            return Config.LIVE_CHAT_ID;
        }
    },
    methods: {
        // onResize(){
        //     if(!this.injected){
        //         this.loadScript();
        //     }
        //     else{
        //         this.validateLiveChatVisibility();
        //     }
        // },
        // validateLiveChatVisibility(){
        //     const ic = window.Intercom;
        //     if(typeof ic === 'function'){
        //         ic('update', {'hide_default_launcher': this.$screen.xs});
        //     }
        // },
        loadScript(){
            if(!this.injected && this.labelSettings.label === 'aev'){
                const script1 = document.createElement('script');
                script1.id = 'intercom-settings';
                script1.text = `
                window.intercomSettings = {
                    api_base: "https://api-iam.eu.intercom.io",
                    app_id: "${this.liveChatId}",
                };
                `;
                document.body.appendChild(script1);

                const script2 = document.createElement('script');
                script2.text = `
                    (function() {
                    var w = window;
                    var ic = w.Intercom;
                    if (typeof ic === "function") {
                        ic('reattach_activator');
                        ic('update', w.intercomSettings);
                    } else {
                        var d = document;
                        var i = function() {
                        i.c(arguments);
                        };
                        i.q = [];
                        i.c = function(args) {
                        i.q.push(args);
                        };
                        w.Intercom = i;
                        var l = function() {
                        var s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = 'https://widget.intercom.io/widget/${this.liveChatId}';
                        var x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                        };
                        if (document.readyState === 'complete') {
                        l();
                        } else if (w.attachEvent) {
                        w.attachEvent('onload', l);
                        } else {
                        w.addEventListener('load', l, false);
                        }
                    }
                    })();
                `;
                document.body.appendChild(script2);
                this.injected = true;
            }
        }
    }
};
