<template>
  <PartialOverlayWithCloseButton v-model="showHospitalOverlay">
    <ValidationObserver 
      ref="hospitalOverlayForm"
      v-slot="{ invalid }"
    >
      <section>
        <PlainText class="mt-3 d-inline">{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.paragraph') }}</PlainText>        
        <LinkText class="d-inline" href="https://aevitae.com/zoek-jouw-zorgverlener" target="_blank"> {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.paragraphLink') }}.</LinkText>
      </section>

      <section>
        <div class="container mt-3 mb-3">
          <div class="row justify-content-between">
            <div class="col-12 col-md-8 p-0 mb-3">
              <label> {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.yourBasicInsurance') }}</label>
              <BasicInsuranceDropdown v-model="modelValue" />
            </div>
            <div class="col-12 col-md-4 p-0 pl-md-3 mb-3">
              <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.year') }}</label>
              <Dropdown :options="yearOptions" v-model="selectedYear" :useTranslationKeys="false" :disabled="!isEjw" />
            </div>
            <div class="col-12 col-md-8 p-0 mb-3">
              <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.nearby') }}</label>
              <LocationInputField v-model="location" @zipcode="(x) => zipcode = x" @city="(x) => city = x" @blur="resetData()" />                       
            </div>
            <div class="col-12 col-md-4 p-0 pl-md-3 mb-3">
              <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.maxDistance') }}</label>
              <DistanceDropdown v-model="selectedDistance" />
            </div>
          </div>
          <div class="row">
            <Button :disabled="isLoading" class="mt-3 button-search-hospital" @click.native="getListOfHospitals()">{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.search') }}</Button>
          </div>
        </div>
      </section>

      <Loading :isLoading="isLoading">
        <table v-if="hospitals?.length > 0" class="table-hospital">
          <thead>
            <tr class="header-box align-items-center">
              <th class="header">{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.city') }}</th>
              <th class="header">{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.hospital') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="data-box" v-for="(hospital, i) in hospitals" :key="i">
              <td class="data">{{ hospital.city }}</td>
              <td class="data">{{ hospital.name }}</td>
            </tr>
          </tbody>
        </table>
        <PlainText v-if="isFailedCall" class="no-results">
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.failedCall') }}
        </PlainText>
        <PlainText v-else-if="hospitals?.length === 0" class="no-results">
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.noResults') }}
        </PlainText>
      </Loading>
    </ValidationObserver>
  </PartialOverlayWithCloseButton>
</template>

<script>
import Zorgzoeker from '@/constants/zorgzoeker';

import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import Dropdown from '@/components/shared/form/Dropdown.vue';
import InputField from '@/components/shared/form/InputField.vue';
import FormFieldValidation from '@/components/shared/form/FormFieldValidation.vue';

import DistanceDropdown from '@/components/shared/form/zorgzoeker/DistanceDropdown.vue';
import BasicInsuranceDropdown from '@/components/shared/form/zorgzoeker/BasicInsuranceDropdown.vue';
import LocationInputField from '@/components/shared/form/zorgzoeker/LocationInputField.vue';

import PartialOverlayWithCloseButton from '@/components/shared/2.0-import/PartialOverlayWithCloseButton.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import LinkText from '@/components/shared/2.0-import/text/LinkText.vue';
import Button from '@/components/shared/button/Button.vue';
import Loading from '@/components/shared/2.0-import/Loading.vue';

import ZorgzoekerUtils from '@/utils/zorgzoeker';

export default {
  name: 'HospitalOverlay',
  emits: ['open'],
  components: {
    ValidationObserver,
    Dropdown,
    InputField,
    FormFieldValidation,
    DistanceDropdown,
    BasicInsuranceDropdown,
    LocationInputField,
    PartialOverlayWithCloseButton,
    PlainText,
    LinkText,
    Button,
    Loading,
  },
  props: {
    value: {
      type: Number
    },
  },
  data() {
    return {
      selectedYear: null,
      selectedDistance: 10000,
      hospitals: null,
      isLoading: false,
      isFailedCall: false,
      location: '',
      zipcode: null,
      city: null
    };
  },
  mounted() {
    this.$store.dispatch(Zorgzoeker.Actions.GET_CITIES);
    this.selectedYear = this.isEjw? this.currentYear + 1 : this.currentYear;
  },
  computed: {
    ...mapGetters(['cities', 'isEjw']),
    showHospitalOverlay:{
      get(){
        return this.value != null;
      },
      set(value){
        if(!value){
           this.$emit('input', null);
        }
      }
    },
    yearOptions(){
      var options = [ this.currentYear];
      if(this.isEjw){
        options.push(this.currentYear + 1);
      }
      return options.map(x => ({ value: x, text: x }));
    },
    modelValue:{
      get(){
        return this.value;
      },
      set(v){
        this.$emit('input', v);
      }
    },
    currentYear() {
      return new Date().getFullYear();
    },
    isMobile() {
      return this.$screen.xs;
    }
  },
  methods: {
    async getListOfHospitals() {
      const isValid = await this.$refs.hospitalOverlayForm.validate();
      if (isValid) {
        this.resetData();
        this.isLoading = true;
        ZorgzoekerUtils.Get_Hospitals(this.zipcode, this.city, this.selectedDistance, this.modelValue, this.selectedYear)
          .then(x => {
            this.hospitals = x.data;
          })
          .catch(() => {
            this.isFailedCall = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    resetData() {
      this.hospitals = null;
      this.isFailedCall = false;
    }
  },
  watch: {
    selectedDistance() {
      this.resetData();
    },
    value(){
      this.resetData();
    }
  }
};
</script>

<style lang='scss' scoped>
.container {
  max-width: 100%;
}

.table-hospital {
  width: 100%;
}

.header-box {
  height: 57px;
  background-color: var(--color-grey-050);
}

.header-box th {
  padding: 18px 0px 18px 24px;
  font-weight: normal;
}

.data-box {
  border-bottom: solid 1px var(--color-primary-100)
}

.data-box td {
  padding: 18px 0px 18px 24px;
}

.header {
  color: var(--color-grey-500);
}

.row-box {
  width: auto;
  height: 50px;
  border-bottom: 1px solid var( --color-primary-100);
}

.data {
  font-size: 16px;
  color: var(--color-grey-900);
  line-height: 22px;
  padding: 0px 6px;
}

.no-results {
  text-align: center;
}

.button-search-hospital {
  width: 78px;
}
</style>