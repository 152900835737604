<template>
  <div class="info-btn">
    <InfoButton
      @click.native="openServiceLink()"
      :size="48"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import InfoButton from '@/assets/icons/InfoButton.vue';

export default {
  name: 'InformationButton',
  components: {
    InfoButton
  },
  computed: {
    ...mapGetters(['labelSettings']),
    serviceLink() { 
      return this.$tExistsOrDefault(`help.contact.informationLink.${this.labelSettings.label}`);
    },
  },
  methods: {
    openServiceLink() {
      window.open(this.serviceLink, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.info-btn {
  border-radius: 50%;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%);
}
.info-btn:hover {
  cursor: pointer;
  transition: transform 250ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
  transform: scale(1.1);
}
</style>