import { render, staticRenderFns } from "./HospitalsOverlay.vue?vue&type=template&id=af749330&scoped=true"
import script from "./HospitalsOverlay.vue?vue&type=script&lang=js"
export * from "./HospitalsOverlay.vue?vue&type=script&lang=js"
import style0 from "./HospitalsOverlay.vue?vue&type=style&index=0&id=af749330&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af749330",
  null
  
)

export default component.exports