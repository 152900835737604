import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMatchHeights from 'vue-match-heights';
// import cacheBust from './helpers/cache-bust';

import Config from '@/constants/config';
import vueDebounce from 'vue-debounce';
import VueMask from 'v-mask';
import './validation/custom-validation-rules';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNotch,
  faEdit,
  faExclamationCircle,
  faFileAlt,
  faFileMedical,
  faFilePowerpoint,
  faGlobe,
  faInfoCircle,
  faPlusCircle,
  faTimesCircle,
  faPhone,
  faEnvelope,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faPencilAlt,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PrettyCheckbox from 'pretty-checkbox-vue';
import VueTelInput from 'vue-tel-input';
import vSelect from 'vue-select';
import VShowSlide from 'v-show-slide';
import InformationIcon from '@/components/shared/InformationIcon.vue';
import FloatingVue from 'floating-vue';
import VueHorizontal from 'vue-horizontal';
import VueCookies from 'vue-cookies';
import Toast from 'vue-toastification';

library.add(
  faCircleNotch,
  faEdit,
  faExclamationCircle,
  faFileAlt,
  faFileMedical,
  faFilePowerpoint,
  faGlobe,
  faInfoCircle,
  faPlusCircle,
  faTimesCircle,
  faPhone,
  faEnvelope,
  faCalendarAlt,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faPencilAlt,
  faTimes
);

Vue.config.productionTip = false;

const bootstrap = function () {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
};

axios.get('/config.json', {
    headers: { 'Cache-control': 'no-cache' }
  }
).then(({ data }) => {
  Config.VERSION = data.Application.releaseTag;
  Config.LIVE_CHAT_ID = data.liveChatId;
  Config.API.BASE_URL = data.api.baseUrl;
  Config.COLLECTIVITY_LABEL_SETTINGS = data.collectivityLabelSettings;
  Config.ENVIRONMENT = data.environment;

  // cacheBust.forceNewestVersion(); //--> only use if elegant cache-control is not enough

  bootstrap();
});
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);
Vue.component('information-icon', InformationIcon);
Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  containerClassName: 'toast-container-class',
  maxToasts: 20,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.some(t => t.type === toast.type)) {
      return false;
    }
    return toast;
  }
});
Vue.use(FloatingVue, {
  strategy: 'fixed',
  themes: {
    'oaz': {
      $extend: 'dropdown'
    },
  }
});
Vue.use(VueHorizontal);
Vue.use(VueMask);
Vue.use(vueDebounce);
Vue.use(VueMatchHeights, {});
Vue.use(PrettyCheckbox);
Vue.use(VueTelInput);
Vue.use(VShowSlide);
Vue.use(VueCookies);
Vue.directive('focus', {
  inserted: function(el, binding) {
    if(binding.value !== false) {
      el.focus();
    }
  }
});
Vue.directive('visible', function(el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
}); 

const screenSizeValues = () => { 
  return {
    xxs: window.innerWidth <= 399,
    xs: window.innerWidth <= 767,
    sm: window.innerWidth > 767 && window.innerWidth <= 991,
    md: window.innerWidth > 991 && window.innerWidth <= 1199,
    lg: window.innerWidth > 1199 && window.innerWidth <= 1920,
    lt: {
      sm: window.innerWidth <= 767,
      md: window.innerWidth <= 991,
      lg: window.innerWidth <= 1199
    },
    gt: {
      xs: window.innerWidth > 767,
      sm: window.innerWidth > 991,
      md: window.innerWidth > 1199,
      lg: window.innerWidth > 1920
    }
  };
};

const screenBreakPoints = Vue.observable({ screen: screenSizeValues() });

Object.defineProperty(Vue.prototype, '$screen', {
  get () {
    return screenBreakPoints.screen;
  }
});

window.addEventListener('resize', () => {
  screenBreakPoints.screen = screenSizeValues();
});
