import { mapGetters } from "vuex";
import i18n from 'vuex-i18n';
import router from '@/router'
import Vue from 'vue';
import VueGtag from 'vue-gtag';

export const googleAnalyticsTag = {
  data() {
    return {
      googleAnalyticsTagInjected: false,
    };
  },
  mounted() {
    this.loadScript();
  },
  computed: {
    ...mapGetters(["labelSettings"])
  },
  methods: {
    loadScript() {
      if (!this.googleAnalyticsTagInjected && this.labelSettings.label === "aev") {
        Vue.use(VueGtag, {
          enabled: false,  
          config: { id: 'G-EPGQZCY7TD' },
          appName: 'OnlineAfsluitenZorg',
          pageTrackerEnabled: true,
          pageTrackerScreenviewEnabled: true,
          params: {
            send_page_view: true
          },
          pageTrackerTemplate(to) {
            return {
              page_title: to.meta.title !== undefined && to.meta.title !== null
                ? i18n.store.state.translations[i18n.store.state.locale]['tabbladTitles.default'] + ' | ' + i18n.store.state.translations[i18n.store.state.locale][to.meta.title] 
                : i18n.store.state.translations[i18n.store.state.locale]['tabbladTitles.default'],
              page_path: to.path
            };
          }
        }, router);

        this.googleAnalyticsTagInjected = true
      }
    },
  },
};
