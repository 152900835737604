import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import VueGtag from 'vue-gtag';
import Contracts from '@/constants/contracts';
import CollectivityChoice from '@/constants/CollectivityChoice';
import ContractCodes from '@/constants/ContractCodes';

import Home from '@/views/Home.vue';
import ContractOverview from '@/views/ContractOverview.vue';
import Insured from '@/views/Insured.vue';
//import MedicalSelection from '@/views/MedicalSelection.vue';
import PersonalData from '@/views/PersonalData.vue';
import PersonalExtraData from '@/views/PersonalExtraData.vue';
import Configuration from '@/views/Configuration.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import Overview from '@/views/Overview.vue';
import PremiumCalculation from '@/views/PremiumCalculation.vue';
import Complete from '@/views/Complete.vue';
import RevisitLoader from '@/views/RevisitLoader.vue';

import Labels from '@/constants/labels';
import Environments from '@/constants/environments';
import Config from '@/constants/config';
import i18n from 'vuex-i18n';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: {
        name: 'home',
        params: {
          lang: 'nl'
        }
      }
    },
    {
      name: 'home',
      path: '/:lang(nl|en)',
      component: Home,
      meta: {
        logo: true
      },      
      children: [
        {
          name: 'contract-overview',
          path: ':contractCode(\\d+)',
          component: ContractOverview,
          meta: {
            logo: true
          },  
          props (route) {
            const props = {
              ...route.params
            };
            props.contractCode = parseInt(props.contractCode, 10);

            if (props.contractCode === ContractCodes.Laef) {
              store.dispatch(Contracts.Actions.SET_COLLECTIVITY_CHOICE, CollectivityChoice.CollectivityChoiceEnum.Nee);
            }
        
            return props;
          },
        }
      ]
    },
    {
      path: '/:contractCode(\\d+)',
      redirect: to => ({
        name: 'contract-overview',
        params: {
          lang: 'nl',
          contractCode: to.params.contractCode
        }
      })
    },
    {
      name: 'insured',
      path: '/:lang(nl|en)/:contractCode(\\d+)/insured',
      component: Insured,
      props (route) {
        const props = {
          ...route.params
        };
        props.contractCode = parseInt(props.contractCode);
        return props;
      },
      meta: {
        step: 1,
        stepProgress: 1,
        title: 'tabbladTitles.insuredPage',
      }
    },

    {
      path: '/:lang(nl|en)/:contractCode(\\d+)/configuration/:activationStep(\\d+)?/:openMedicalSelectionOverlay?',
      name: 'configuration',
      component: Configuration,
      props (route) {
        const props = {
          ...route.params
        };
        props.activationStep = parseInt(props.activationStep);
        let boolean = false;
        if (typeof props.openMedicalSelectionOverlay === 'string' && props.openMedicalSelectionOverlay === 'true'){
          boolean = true;
        }  
        if (typeof props.openMedicalSelectionOverlay === 'string' && props.openMedicalSelectionOverlay === 'false'){
          boolean = false;
        }  

        props.openMedicalSelectionOverlay = boolean;
        return props;
      },
      meta: {
        step: 2,
        title: 'tabbladTitles.configurationPage',
      }
    },
    {
      name: 'personal_data',
      path: '/:lang(nl|en)/:contractCode(\\d+)/personal_data',
      component: PersonalData,
      props: true,
      meta: {
        step: 3,
        stepProgress: 2,
        title: 'tabbladTitles.personalPage',
      }
    },
    {
      name: 'personal_extra_data',
      path: '/:lang(nl|en)/:contractCode(\\d+)/personal_extra_data',
      component: PersonalExtraData,
      props: true,
      meta: {
        step: 3,
        stepProgress: 3,
        title: 'tabbladTitles.personalPage',
      }
    },
    {
      name: 'overview',
      path: '/:lang(nl|en)/:contractCode(\\d+)/overview',
      component: Overview,
      props: true,
      meta: {
        step: 4,
        stepProgress: 1,
        title: 'tabbladTitles.overviewPage',
      }
    },
    {
      name: 'complete',
      path: '/:lang(nl|en)/:contractCode(\\d+)/complete',
      component: Complete,
      props: true,
      meta: {
        logo: true,
        title: 'tabbladTitles.confirmPage'
      },
      beforeEnter(to, from, next) {
        if(!from.name) {
          next({
            path: '/',
          });
        } else {
          next();
        }
      }
    },
    {
      path: '/:lang(nl|en)/:contractCode(\\d+)/configuration/premiumCalculation',
      name: 'premiumCalculation',
      component: PremiumCalculation,
      props: true
    },
    {
      path: '/:lang(nl|en)?/revisit-loader/:id/:type(email|cookie)',
      name: 'RevisitLoader',
      component: RevisitLoader,
      props: true,
      meta: {
        logo: true
      }
    },
    {
      path: '*',
      redirect: '/nl/notFound'
    },
    {
      name: 'notFound',
      path: '/:lang(nl|en)/notFound',
      alias: '/:lang(nl|en)/*',
      component: PageNotFound,
      meta: {
        logo: true
      }
    }
  ],
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (language && store.getters.language !== language) {
    store.dispatch(Labels.Actions.LOAD_BY_LANGUAGE, language)
      .then(() => next());
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  const $t = i18n.store.state.translations[i18n.store.state.locale];

  const title = to.meta.title !== undefined && to.meta.title !== null
    ? $t['tabbladTitles.default'] + ' | ' + $t[to.meta.title] 
    : $t['tabbladTitles.default'];

  Vue.nextTick(() => {
    document.title = title;
    const baseUrl = window.location.origin;
    //update google analytics pageView
    if(Vue.$gtag && Environments.Production === Config.ENVIRONMENT && store.getters.labelSettings.label === 'aev') {
      Vue.$gtag.pageview({
        page_title: title,
        page_path: to.path
      });
    }
    //update siteImprove pageView
    if (window._sz) {
      window._sz.push(['trackdynamic', {
        'url': baseUrl + to.fullPath,
        'ref': baseUrl + from.fullPath,
        'title': title
      }]);
    }
  });
});

export default router;
