<template>
  <svg :width="size" :height="size" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g fill="none" fill-rule="evenodd">
        <circle fill="#FFCE34" cx="24" cy="24" r="24"/>
        <g stroke="#000" stroke-width=".7">
            <path d="M18.64 17.006c-1.625 1.076-2.628 2.238-3.01 3.484-.384 1.247-.273 2.506.331 3.777l2.938 5.235c.725 1.132 1.763 1.86 3.113 2.185 1.35.325 2.818-.028 4.402-1.06a9.624 9.624 0 0 0-.862-1.737 8.678 8.678 0 0 0-1.16-1.47c-1.69.953-2.828.953-3.416 0l-2.077-3.79c-.656-1.25-.099-2.391 1.67-3.425-.25-.57-.536-1.12-.855-1.65a15.22 15.22 0 0 0-1.074-1.55z"/>
            <path d="M22.012 19.097h11.013c.134 0 .262.045.382.136.12.09.177.236.168.437v8.342c0 .308-.056.527-.168.657a.595.595 0 0 1-.382.221H27.8" stroke-linecap="round"/>
            <path d="m21.682 21.22 4.533 3.614c.127.069.237.103.332.103a.57.57 0 0 0 .303-.103l6.532-5.343" stroke-linecap="round"/>
        </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'InfoButton',
  props: {
    size: Number
  },
};
</script>